const appConfig = {
  apiPrefix: "https://starbazar.5techg.com/api/v1/web",
  // apiPrefix: "http://localhost:5006/api/v1/web",

  authenticatedEntryPath: "/dashboard",
  unAuthenticatedEntryPath: "/sign-in",
  tourPath: "/",
  locale: "en",
  enableMock: true,
};

export default appConfig;
