import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
import { apiPutAdminToken } from "../../services/AdminService";
import { PERSIST_STORE_NAME } from "../../constants/app.constant";
import deepParseJson from "../../utils/deepParseJson";

const firebaseConfig = {
  apiKey: "AIzaSyBmFNM2bomqB00bIxm96eOGv3HRBSbjfTs",
  authDomain: "fir-cloud-messaging-3a3e1.firebaseapp.com",
  projectId: "fir-cloud-messaging-3a3e1",
  storageBucket: "fir-cloud-messaging-3a3e1.appspot.com",
  messagingSenderId: "943107571023",
  appId: "1:943107571023:web:5905c666b8c6e2a340193a",
};

const app = initializeApp(firebaseConfig);

export const messaging = getMessaging(app);

export const generateToken = async () => {
  const rawPersistData = localStorage.getItem(PERSIST_STORE_NAME);
  const persistData = deepParseJson(rawPersistData);

  let admin_id = persistData?.auth?.user?.id;

  let loginToken = persistData?.auth?.session?.token;
  let permission = Notification.permission;

  if (permission !== "granted") {
    permission = await Notification.requestPermission();
  }

  if (permission === "granted") {
    const token = await getToken(messaging, {
      vapidKey:
        "BH1vj6wzFrnZ12e1JaOnRmrLKkHH1l50f2cQlR4o_q3NzLHxgC9mKR4tWmv_QC1njQ_044h8pQnJvAQeiC6TUEw",
    });

    const storedToken = localStorage.getItem("notificationToken");

    if (loginToken && (!storedToken || storedToken !== token)) {
      localStorage.setItem("notificationToken", token);

      const response = await apiPutAdminToken({
        token: token,
        admin_id: Number(admin_id),
      });
    }
  }
};
