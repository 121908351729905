import React, { Suspense, useMemo, useEffect, useState } from "react";
import { Toast, Notification } from "../components/ui";
import { Loading } from "../components/shared";
import useAuth from "../utils/hooks/useAuth";
import { onMessage } from "firebase/messaging";
import { generateToken, messaging } from "../components/firebase/firebase";
import deepParseJson from "../utils/deepParseJson";
import { PERSIST_STORE_NAME } from "../constants/app.constant";

import notification from "./notify.mp3";

const Layout = () => {
  const rawPersistData = localStorage.getItem(PERSIST_STORE_NAME);
  const persistData = deepParseJson(rawPersistData);

  let accessToken = persistData?.auth?.session?.token;

  // const audioContext = new (window.AudioContext || window.webkitAudioContext)();
  // let notificationSound;

  // const loadNotificationSound = async () => {
  //   const response = await fetch(notification);
  //   const buffer = await response.arrayBuffer();
  //   notificationSound = await audioContext.decodeAudioData(buffer);
  // };

  // const playNotificationSound = () => {
  //   const source = audioContext.createBufferSource();
  //   source.buffer = notificationSound;
  //   source.connect(audioContext.destination);
  //   source.start(0);
  // };
  // const { auth } = store.getState();
  // let accessToken = auth?.session?.token;
  // const [mssg, setMssg] = useState("");

  useEffect(() => {
    generateToken();

    onMessage(messaging, (payload) => {
      setTimeout(() => {
        Toast.push(
          <Notification
            title={payload?.notification?.title}
            type="success"
            duration={15000}
          >
            {payload?.notification?.body}
          </Notification>,
          {
            placement: "top-end",
          }
        );
      }, 100);
    });
  }, [accessToken]);

  const { authenticated } = useAuth();
  // const authenticated=true
  const AppLayout = useMemo(() => {
    if (authenticated) {
      return React.lazy(() => import("./main"));
    }

    return React.lazy(() => import("./authLayout"));
  }, [authenticated]);

  return (
    <Suspense
      fallback={
        <div className="flex flex-auto flex-col h-[100vh]">
          <Loading loading={true} />
        </div>
      }
    >
      <AppLayout />
    </Suspense>
  );
};

export default Layout;
